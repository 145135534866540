<template>
  <div>
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
      <v-dialog v-model="dialogCircuit" width="80%">
        <s-toolbar color="primary" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col lg="7">
                <v-data-table
                  dense
                  item-key="AccID"
                  :items="circuits"
                  height="320"
                  :headers="[
                    { value: 'AccID', text: 'ID' },
                    { value: 'AccName', text: 'Circuito' },
                  ]"
                  @dblclick:row="createCircuit"
                  v-model="selectedCircuit"
                  @click:row="rowClickCircuit"
                />
              </v-col>
              <v-col lg="5">
                <s-crud v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <v-card>
      <s-toolbar
        @save="save()"
        :save="isEditable"
        :approve="optionApproved"
        close
        @close="$emit('close')"
        :transactionApproved="transactionApproved"
        :label="'Requerimiento  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)"
      />
      <v-divider></v-divider>
      <v-col style="padding-top: 18px">
        <v-row justify="center">
          <v-col lg="4" cols="12" style="padding-top: 0px; padding-bottom: 0px"
            ><s-text disabled label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
          </v-col>
          <v-col lg="4" cols="12" style="padding-top: 0px; padding-bottom: 0px">
            <s-select-area disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
          </v-col>
          <v-col lg="2" cols="12" style="padding-top: 0px; padding-bottom: 0px"
            ><s-date disabled label="Fecha" :value="requirement.ReqDate" />
          </v-col>

          <v-col lg="2" cols="12" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
            <s-select-definition :dgr="27" :def="1128" label="Estado" :disabled="!isEditable" v-model="ReqStatus" />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" v-if="isEditable">
            <s-toolbar
              color=""
              label="Editar Item"
              :add="isEditable"
              :edit="isEditable"
              :remove="isEditable"
              @edit="edit()"
              @add="add()"
              @removed="removeItem()"
              @save="add()"
              @clear="clearForm()"
              :clear="isEditable"
            />
            <v-divider></v-divider>
            <v-card rounded="" class="elevation-0">
              <v-col style="padding-bottom: 24px">
                <v-row justify="center" v-if="Type != 3">
                  <v-col class="s-col-form" lg="6" cols="12">
                    <s-text label="Cantidad" decimal ref="DerQuantity" v-model="item.DerQuantity" />
                  </v-col>
                  <v-col class="s-col-form" lg="3" cols="12">
                    <s-text label="Stock" disabled ref="" v-if="article" v-model="article.ArtStock" />
                  </v-col>
                  <v-col class="s-col-form" lg="3" cols="12">
                    <s-text label="Unidad" disabled v-if="article" v-model="article.UnitOfMeasuarement" />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col lg="12" class="s-col-form">
                    <s-text label="Comentario" v-model="item.DerObservation" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <s-textarea label="Comentario General" v-model="requirement.ReqObservation"></s-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>

          <v-col :lg="!isEditable ? 12 : 8">
            <s-toolbar label="Detalle" load></s-toolbar>
            <v-card rounded="" class="elevation-0">
              <v-col style="padding: 0px">
                <v-data-table
                  mobile-breakpoint="0"
                  item-key="DerNumLine"
                  dense
                  :items="items"
                  :height="!isEditable ? '290' : $vuetify.breakpoint.xs ? 'auto' : '272'"
                  :headers="headersDetail"
                  class="elevation-0"
                  v-model="selected"
                  :items-per-page="-1"
                  @click:row="rowClick"
                >
                </v-data-table>
              </v-col>
              <v-col lg="12" class="pt-0" v-if="transactionApproved != null">
                <h3 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                  Desaprobado: {{ transactionApproved.AtsObservation }}
                </h3>
                <h3 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">Aprobado</h3>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sRequirementPermit from "../../../services/Logistics/PermitService";
import _sApprovalTransaction from "../../../services/Security/ApprovalTransactionService";
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import ReqPurchaseByUser from "./ReqPurchaseByUser.vue";

export default {
  name: "RequirementService",
  components: { sArticleSearch, ReqPurchaseByUser },
  computed: {
    user() {
      return this.$fun.getUserInfo();
    },
    isEditable() {
      return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null || (this.requirement.ReqStatus == 3 && this.isUserCreated == true);
    },
    isVisibleEspecial() {
      return this.$fun.getUserInfo().CtrContract.AreID == this.$const.ID_AREA_MANTENIMIENTO;
    },
  },
  props: {
    value: { default: 1, type: Number },
    TypeRequirement: { default: 1, type: Number },
    requirementsDetail: { default: [], type: Array },
  },
	created() {
		this.items = this.requirementsDetail;
		this.Type = this.TypeRequirement;
	},
  data: () => ({
    isError : {
      error : 0,
      message : ""
    },
    permit: [],
    editItem: false,
    selectedCircuit: [],
    filterLevels: {},
    configLevels: {
      model: {
        AccID: "int",
        AlvID: "ID",
        SecStatus: "boleam",
        PstID: "int",
        PstName: "string",
      },
      service: _sApprovalLevel,
      headers: [
        { text: "Cargo", value: "PstName" },
        { text: "Nivel", value: "TypeLevelText" },
      ],
    },
    viewLoad: true,
    circuits: [],
    dialogCircuit: false,
    Type: 0,
    isUserCreated: true,
    transactionApproved: null,
    dialogDissaproved: false,
    ReqStatus: 0,
    optionApproved: false,
    itemsConcept: [],
    itemsAccount: [],
    SitID: 0,
    SisID: 0,
    ComID: 0,
    ConName: null,
    AcoID: null,
    itemsRemoved: [],
    article: null,
    selected: [],
    typeCostObject: null,
    requirement: {
      AreID: 0,
      ReqDate: null,
      CmpID: null,
      UsrID: null,
      ReqID: null,
      ReqCommitted: null,
      ReqObservation: "",
      ReqStatus: 0,
    },
    item: {
      DerNumLine: 0,
      BslName: "",
      CceName: "",
      PrjName: "",
      DerDescription: "",
      BslObject: 0,
      CceObject: 0,
      TypeCostObject: null,
      DerObservation: "",
      PrjObject: 0,
      SisObject: null,
      SitObject: null,
      ComObject: null,
      DerQuantity: "",
    },
    TypeCost: 0,
    centercost: 0,
    bslID: 0,
    dialog: false,
    filterRequirement: { BeginDate: null, EndDate: null },
    items: [],
    //service: Service,
    headersDetail: [
      { text: "Code", value: "ArtCode", width: 70 },
      { text: "Und", value: "UnitMeasurement", sortable: false, width: 40 },
      { text: "Cant", value: "DerQuantity", sortable: false,  width: 40 },
      { text: "Descripción", value: "DerDescription", width: 500 },
      { text: "T.Costo", value: "TypeCostName", width: 100, },
      { text: "Centro de Costo", value: "CceName", width: 300 },
      { text: "Linea de Negocio", value: "BslName", sortable: false, width: 300 },
      { text: "Proyecto", value: "PrjName", sortable: false,  width: 300},
      { text: "T.Mantenimiento", value: "TypeMaintenanceName", sortable: false },
      { text: "Ubicación", value: "SitName", sortable: false,  },
      { text: "Sistema", value: "SisName", sortable: false,  },
      { text: "Componente", value: "ComName", sortable: false,  },
      { text: "Comentario", value: "DerObservation", sortable: false,  },
    ],
    quantityEnter: 0,
    itemSelectedOrderPurchase: null,
    requirementPurchaseID: [],
    itemsRequirementsByUser : []
  }),
  methods: {
    createCircuit(item) {
      if (this.items.length < 1) {
        this.$fun.alert("No se ha registrado ningún detalle", "warning");
        return;
      }

      this.requirement.TypeRequirement = this.Type;
      this.requirement.UsrID = this.$fun.getUserID();
      this.requirement.UsrCreateID = this.$fun.getUserID();
      this.requirement.UsrUpdateID = this.$fun.getUserID();
      this.requirement.ReqStatus = this.ReqStatus;
      this.requirement.Details = [...this.items, ...this.itemsRemoved];

      this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question").then((r) => {
        if (r.value) {
          _sRequirement.save(this.requirement, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
            this.$fun.alert("Guardado Correctamente", "success");
            this.$emit("close");
          });
        }
      });
    },

    removeItem() {
      if (this.items.length > 0) {
        if (this.selected[0].ReqID > 0) {
          this.selected[0].SecStatus = 0;
          this.itemsRemoved.push(this.selected[0]);
        }
        this.items = this.items.filter((x) => x != this.selected[0]);
        this.selected = this.items.length > 0 ? [this.items[0]] : [];
      }
    },

    clearForm() {
      this.editItem = false;
      this.ConName = null;
      this.AcoID = null;
      this.item.DerDescription = "";
      this.item.DerObservation = "";
      this.item.DerQuantity = "";
      this.item.DerNumLine = 0;
      if (this.Type == 3) this.$refs.DerDescription.focus();
      else this.article = null;
    },
    
    edit() {
      if (this.selected.length > 0) {
        this.editItem = true;
        let row = this.selected[0];
        this.item.TypeCostObject = { TypeCost: row.TypeCost, TypeCostName: row.TypeCostName };
        this.item.PrjObject = { GenHelper: row.PrjCode, GenDescription: row.PrjName };
        this.item.CceObject = { CceID: row.CceID, CceName: row.CceName };
        this.item.BslObject = { BslID: row.BslID, BslName: row.BslName };
        console.log('iii ', this.item);
        this.ConName = row.ConName;
        console.log(row.TypeCost, row.TypeCostName);
        this.$nextTick().then((r) => {
          this.AcoID = row.ReqAccount;
        });
        if (row.SitID) this.item.SitObject = { GenID: parseInt(row.SitID), GenDescription: row.SitName };
        if (row.SitID) this.item.SisObject = { GenID: parseInt(row.SisID), GenDescription: row.SisName };
        if (row.SisID) this.item.ComObject = { GenID: parseInt(row.ComID), GenDescription: row.ComName };
        this.item.DerDescription = row.DerDescription;
        this.item.DerObservation = row.DerObservation;
        this.item.DerNumLine = row.DerNumLine;
        if (row.TypeMaintenance)
          this.item.TypeMaintenanceObject = { DedValue: parseInt(row.TypeMaintenance), DedDescription: row.TypeMaintenanceName };
        this.article = {
          ArtCode: row.ArtCode,
          ArtName: row.DerDescription,
          ArtStock: row.ArtStock,
          UnitOfMeasuarement: row.UnitMeasurement,
          WhsCode: row.WhsID,
          WhsName: row.WhsName,
        };
        this.item.DerQuantity = row.DerQuantity;
        console.log('itemmdsdfsdfs ', this.item);
      }
    },

    async add() {
      if (this.item.DerDescription.length <= 1) {
        this.$fun.alert("Complete Descripción", "warning");
        return;
      }
      if (this.Type != 3)
        if (this.item.DerQuantity <= 0 || this.item.DerQuantity.toString().trim() == "") {
          this.$fun.alert("Cantidad debe ser mayor a Cero", "warning");
          this.$refs.DerQuantity.focus();
          return;
        }
      if (this.TypeRequirement == 2 && this.article.ArtStock < this.item.DerQuantity) {
        this.$fun.alert("No hay stock suficiente", "warning");
        return;
      }

      this.items[this.item.DerNumLine - 1].DerDescription = this.item.DerDescription;
      this.items[this.item.DerNumLine - 1].TypeCostName = this.item.TypeCostObject.TypeCostName;
      this.items[this.item.DerNumLine - 1].TypeCost = this.item.TypeCostObject.TypeCost;
      if (this.item.TypeMaintenanceObject) {
        this.items[this.item.DerNumLine - 1].TypeMaintenanceName = this.item.TypeMaintenanceObject.DedDescription;
        this.items[this.item.DerNumLine - 1].TypeMaintenance = this.item.TypeMaintenanceObject.DedValue;
      }
      this.items[this.item.DerNumLine - 1].CceName = this.item.CceObject.CceName;
      this.items[this.item.DerNumLine - 1].CceID = this.item.CceObject.CceID;
      this.items[this.item.DerNumLine - 1].BslName = this.item.BslObject.BslName;
      this.items[this.item.DerNumLine - 1].BslID = this.item.BslObject.BslID;
      this.items[this.item.DerNumLine - 1].PrjName = this.item.PrjObject.GenDescription;
      this.items[this.item.DerNumLine - 1].PrjCode = this.item.PrjObject.GenHelper;
      this.items[this.item.DerNumLine - 1].DerObservation = this.item.DerObservation;
      this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
      this.items[this.item.DerNumLine - 1].UnitMeasurement = this.article.UnitOfMeasuarement;
      if (this.item.SitObject) {
        this.items[this.item.DerNumLine - 1].SitName = this.item.SitObject.GenDescription;
        this.items[this.item.DerNumLine - 1].SitID = this.item.SitObject.GenID;
      }
      if (this.item.SisObject) {
        this.items[this.item.DerNumLine - 1].SisID = this.item.SisObject.GenID;
        this.items[this.item.DerNumLine - 1].SisName = this.item.SisObject.GenDescription;
      }
      if (this.item.ComObject) {
        this.items[this.item.DerNumLine - 1].ComID = this.item.ComObject ? this.item.ComObject.GenID : null;
        this.items[this.item.DerNumLine - 1].ComName = this.item.ComObject ? this.item.ComObject.GenDescription : null;
      }
      if (this.Type != 3) {
        this.items[this.item.DerNumLine - 1].ArtCode = this.article.ArtCode;
        this.items[this.item.DerNumLine - 1].DerDescription = this.article.ArtName;
        this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
        this.items[this.item.DerNumLine - 1].UnitMeasurement = this.article.UnitOfMeasuarement;
        if (this.Type == 2) {
          this.items[this.item.DerNumLine - 1].WhsID = this.article.WhsCode;
          this.items[this.item.DerNumLine - 1].WhsName = this.article.WhsName;
          // this.items[this.item.DerNumLine - 1].ArtStock = this.article.ArtStock;
        }
      }

      this.selected = [this.items[this.item.DerNumLine - 1]];
      
      for (let i = 0; i < this.items.length; i++) this.items[i].DerNumLine = i + 1;
      this.clearForm();
    },

    rowClick: function (item, row) {
      this.selected = [item];
    },
    rowClickCircuit: function (item, row) {
      this.selectedCircuit = [item];
      this.filterLevels = { AccID: item.AccID };
    },
    save() {
      this.$fun.alert("Seguro de Guardar?", "question").then((r) => {
        if (r.value) {
          if (this.items.length < 1) {
            this.$fun.alert("No se ha registrado ningún detalle", "warning");
            return;
          }
          if (this.ReqStatus == null) {
            this.$fun.alert("Seleccione Estado", "warning");
            return;
          }
          this.requirement.TypeRequirement = this.Type;
          this.requirement.UsrID = this.$fun.getUserID();
          this.requirement.UsrCreateID = this.$fun.getUserID();
          this.requirement.UsrUpdateID = this.$fun.getUserID();
          this.requirement.ReqStatus = this.ReqStatus;
          this.requirement.SecStatus = 1;
          this.requirement.Details = [...this.items, ...this.itemsRemoved];
          _sRequirement.save(this.requirement, this.$fun.getUserID(), 0).then((r) => {
            if (r.data.length > 1) {
              this.circuits = r.data;
              this.dialogCircuit = true;
            } else {
              this.$fun.alert("Guardado Correctamente", "success");
              this.$emit("close");
            }
          });
        }
      });
    },
  },
  watch: {
    article() {
      this.$nextTick().then(() => {
        if (this.article) {
          this.$refs.DerQuantity.focus();
          this.item.DerDescription = this.article.ArtName;
          if (this.TypeRequirement == 2)
            if (this.article.ArtStock < 0.1) {
              // SI ES REQUERIMIENTO DE ALMACEN
              this.article = null;
              this.$fun.alert("No hay Stock", "warning");
              this.item.DerDescription = "";
            }
        }
      });
    },
  },

};
</script>
