<template>
	<div>
		<!-- <v-dialog
			v-model="dialog"
			width="300"
		>
			<v-card dense>
				<v-container>
					<v-row>
						<v-col>
							<v-btn
								@click="openDialog(1)"
								block
								small
								color="primary"
							>
								<span>Realizar requerimiento</span>
								<v-icon>mdi-application-edit</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								@click="openDialog(2)"
								block
								small
								color="success"
							>
								<span>Ver requerimientos</span>
								<v-icon>mdi-list-box</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<requirement-warehouse-user
			v-if="typeOpenDialog == 1"
			:TypeRequirement='2'
		></requirement-warehouse-user>

		<requirement
			v-if="typeOpenDialog == 2"
			label="Pedidos Almacén"
			:TypeRequirement='2'
		></requirement> -->

		<requirement
			label="Pedidos Almacén"
			:TypeRequirement='2'
		></requirement>
		
	</div>
</template>

<script>
import RequirementWarehouseUser from './RequirementWarehouseUser.vue'
	import Requirement from "./Requirement.vue"

	export default 
	{
		name: "RequirementWarehouse",
		components: {
			Requirement,
			RequirementWarehouseUser,
		},
		props : {

		},
		data() {
			return {
				typeOpenDialog : 0,
				dialog : true
			}
		},

		methods: {
			openDialog(type) {
				this.typeOpenDialog = type;
				this.dialog = false;
			}
		},
	}
</script>