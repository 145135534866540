<template>
	<div>
		<!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
		<div v-if="dialog">
			<v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
			<requirement-warehouse-edit
				:value="RequirementEdit.ReqID"
				@close="closeDialogRequirement()"
				:requirementsDetail="requirementDetailSelected"
				:TypeRequirement="TypeRequirement"
			></requirement-warehouse-edit>
			</v-dialog>
		</div>


		<s-toolbar
			label="Requerimiento Almácen"
			add
			@add="add()"
		></s-toolbar>
		<v-data-table
			:headers="config.headers"
			:items="itemsDetail"
			item-key="DerID"
			show-select
			v-model="requirementDetailSelected"
		></v-data-table>
	</div>
</template>

<script>
	import _sRequirement from "@/services/Logistics/RequirementService"
	import RequirementWarehouseEdit from "@/views/Logistics/Requirement/RequirementWarehouseEdit";

	export default {
		components: {
			RequirementWarehouseEdit,
		},

		props: {
			TypeRequirement: {
				type: Number,
				default: 2
			},
		},

		watch: {
			requirementDetailSelected() {
				console.log(this.requirementDetailSelected);
				this.requirementDetailSelected.forEach((ele, index) => {
					ele.DerNumLine = (index+1);
					ele.DerID = 0;
					ele.ReqID = 0;
				});
				
			}
		},

		data() {
			return {
				RequirementEdit: {},
				dialog : false,
				requirementDetailSelected : [],
				itemsDetail : [],
				config: {
					service: _sRequirement,
					model: {
						DerID: "ID",
						ReqDocumentNumber: "string",
						ReqAuthorizationDate: "date",
						ReqDate: "date",
						ReqAccount: "string",
						ImpDiscount: "double",
						AvgPrice: "numeric",
						DiscPrcnt: "numeric",
						OddNumberLine: 0,DerStatus:''
					},
					headers: [
						{text: "Est",value: "DerStatus", width: 70, }, 
						{text: "N° Requerimiento",value: "ReqDocumentNumber", width: 160, },
						{text: "N° articulo", value: "ArtCode", width: 70, },   
						{text: "Artículo", value: "DerDescription", width: 530, },
						{text: "UM", value: "UnitMeasurement", width: 70,},
						{text: "Cant.Sol.",value: "DerQuantity", width: 90,},
						{ text: "Cant.Aten.",value: "DerQuantityServed",width: 70,},
					// {text: "Cant.Pend.",value: "DerQuantity", width: 100,},
						{text: "F.Autorizacion", value: "ReqAuthorizationDate", width: 120,},
						{text: "Moneda",value: "LastPurCur", width: 100,},
						{text: "U.Prc. Compra",  value: "LastPurPrc", width: 120,},
						{text: "Stock Principal", value: "OnHand", width: 120,},
						{text: "Centro de Costo", value: "CceName", sortable: false, width: 250,},
						{ text: "T.Costo", value: "TypeCostName",},
						{ text: "Linea Negocio",value: "BslName", sortable: false, width: 250, },
						{ text: "Proyecto", value: "PrjName", sortable: false, width: 250 },
						{ text: "Comentario", value: "DerObservation", sortable: false, width: 300 },
					],
				},
			}
		},

		methods: {
			add()
			{
				this.RequirementEdit.ReqID = null;
				this.dialog = true;
			},

			closeDialogRequirement()
			{
				this.dialog = false;
				this.getItemsDetail();
			},

			getItemsDetail() {
				let obj = {
					UsrID : this.$fun.getUserID()
				};

				_sRequirement
				.requirementpurchasebyuser(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsDetail = resp.data;
						this.RequirementEdit.Detail = resp.data;
					}
				})
			},
		},

		created () {
			this.getItemsDetail();
		},
	}
</script>